import React from "react";
import "./FooterStyles.css";

import {
  FaFacebook,
  FaInstagram,
  FaMailBulk,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { BsMailbox2 } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="contact-details">
            <BsMailbox2
              size={20}
              style={{ color: "#fff", marginRight: "1rem", marginTop: "1rem" }}
            />
            <div>
              <p>P.O Box DT2616 Adenta,</p>
              <p>Accra-Ghana</p>
            </div>
          </div>
          <div className="phone">
            <h4>
              <FaPhone
                size={20}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
              +233 (0) 244-762-301
            </h4>
          </div>
          <div className="email">
            <h4>
              <FaMailBulk
                size={20}
                style={{ color: "#fff", marginRight: "1rem" }}
              />
              beafitworld@gmail.com
            </h4>
          </div>
        </div>

        <div className="center">
          <h4>My Projects</h4>
          <p>Summer Health , Wellness and Fitness Retreats</p>
          <p>Senior Citizens Walk</p>
          <p>Health Walk up the Aburi Hill</p>
          <p>Training of Ghana's Most Beautiful</p>
        </div>

        <div className="right">
          <h4>Beafit Ghana</h4>
          <p>
            Beatrice Afful is a certified fitness expert, and currently Ghana’s
            1st Fitness Ambassador for Tourism. I specialize in helping people
            achieve their fitness goals through personalized training programs,
            nutritional guidance, and motivational coaching.{" "}
          </p>
          <div className="socials">
            <a
              href="https://www.facebook.com/beatrice.afful.9?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook
                size={30}
                style={{ color: "#fff", marginRight: "0.7rem" }}
              />
            </a>
            <a
              href="https://twitter.com/beadebully"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter
                size={30}
                style={{ color: "#fff", marginRight: "0.7rem" }}
              />
            </a>
            <a
              href="https://instagram.com/beafit_gh?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram
                size={30}
                style={{ color: "#fff", marginRight: "0.7rem" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
