import React from 'react'
import AboutContent from '../components/AboutContent';
import BackToTopButton from '../components/BackToTopButton';
import Copyright from '../components/Copyright';
import FollowMe from '../components/FollowMe';
import Footer from "../components/Footer";
import Heroimg3 from '../components/HeroImg3';
import Navbar from "../components/Navbar";


const About = () => {
    return (
        <div>
            <Navbar />
            <Heroimg3 heading="ABOUT ME" />
            <AboutContent />
            <FollowMe />
            <Footer />
            <Copyright />
            <BackToTopButton />
        </div>
    )
}

export default About