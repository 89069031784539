import React, { useRef } from "react";
import "./FormStyles.css";
import emailjs from "@emailjs/browser";

import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_rbbv5j6",
                "template_wr28kui",
                form.current,
                "1EKIFwgJXILpql8lL"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    console.log("Message Sent");
                    alert("Messsage Sent");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div className="form-container">
            <div className="form">
                <form ref={form} onSubmit={sendEmail}>
                    <label>Your Name</label>
                    <input type="text" name="user_name" required></input>
                    <label>Email</label>
                    <input type="email" name="user_email" required></input>
                    <label>Subject</label>
                    <input type="text" name="user_subject" required></input>
                    <label>Message</label>
                    <textarea
                        rows="6"
                        placeholder="Type your message here"
                        name="message"
                        required
                    ></textarea>
                    <button type="submit" value="send" className="btn">
                        Submit
                    </button>
                </form>
            </div>
            <div className="form-follow-container">
                <div className="form-socials">
                    <h3>follow me on</h3>
                    <a
                        href="https://www.facebook.com/beatrice.afful.9?mibextid=LQQJ4d"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaFacebook
                            size={40}
                            style={{ color: "#1877F2", marginRight: "1rem" }}
                        />
                    </a>
                    <a
                        href="https://twitter.com/beadebully"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaTwitter
                            size={40}
                            style={{ color: "#1DA1F2", marginRight: "1rem" }}
                        />
                    </a>
                    <a
                        href="https://instagram.com/beafit_gh?igshid=YmMyMTA2M2Y="
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaInstagram
                            size={40}
                            style={{ color: "#000", marginRight: "1rem" }}
                        />
                    </a>
                    <a
                        href="https://www.youtube.com/@beafit4221"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaYoutube
                            size={40}
                            style={{ color: "#FF0000", marginRight: "1rem" }}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Form;
