import React from "react";
import "./ConsultationStyles.css";
import { Link } from "react-router-dom";

const Consultation = () => {
  return (
    <div className="main-body">
      <div className="container">
        <div className="lefty">
          <h1>Weight management and transformation!</h1>
          <p>
            We help individuals to adopt healthy lifestyles, the aim of which is
            to generate the energy they require daily to support their
            lifestyles.
          </p>
        </div>
        <div className="righty">
          <Link to="/contact" className="btn-2">
            Book a Consultation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
