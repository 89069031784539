import React from 'react'
import "./FollowMeStyles.css"
import InstgramPic from "../assets/bea-4.jpeg"



const FollowMe = () => {
    return (

        <div className="follow-me-container">
            <div className="follow-me-left">
                <div className="fml-img-container">
                    <img src={InstgramPic} alt=""></img>
                </div>
            </div>

            <div className="follow-me-right">
                <div className="fmr-container">
                    <h1>Follow me on Instagram!</h1>
                    <p>BEATRICE AFFUL</p>
                    <a href="https://instagram.com/beafit_gh?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" className='btn fm'>follow Me</a>
                </div>
            </div>
        </div>

    )
}

export default FollowMe