import React from "react";
import "./HeroImgStyles.css";

import IntroImg from "../assets/beafit-2.jpg";
import { Link } from "react-router-dom";

const HeroImg = () => {
    return (
        <div className="hero">
            <div className="mask">
                <img className="intro-img" src={IntroImg} alt="IntroImg" />
            </div>
            <div className="content">
                <h1>Welcome to Beafit Ghana</h1>
                <p>
                    Beatrice Afful is a certified fitness expert, and currently Ghana’s
                    1st Fitness Ambassador for Tourism. She helps individuals to adopt
                    healthy lifestyles, the aim of which is to generate the energy they
                    require daily to support their lifestyles.{" "}
                </p>
                <div>
                    <Link to="/services" className="btn">
                        Services
                    </Link>
                    <Link to="/contact" className="btn btn-light">
                        Contact Me
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default HeroImg;
