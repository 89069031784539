import React from "react"
import { Link } from "react-router-dom"
import "./PricingCardStyles.css"

const PricingCard = () => {
    return (
        <div className="pricing">
            <h1 className="pricing-header">Pricing</h1>
            <div className="card-container">
                <div className="card">
                    <h3>- Aerobics / Corporate bonding activities with fun games -</h3>
                    <span className="bar"></span>
                    <p className="btc">Benefits</p>
                    <p>* 2 hour training session *</p>
                    <p>* Multiple fun games *</p>
                    <span className="bar"></span>
                    <p>- 10 to 30 people - <span className="prices">GH 4,000</span> -</p>
                    <p>- 31 to 50 people - <span className="prices">GH 5,500</span> -</p>
                    <p>- 51 to 100 people - <span className="prices">GH 7,000</span> -</p>
                    <Link to="/contact" className="btn pc">Book Now</Link>
                </div>

                <div className="card">
                    <h3>- Health Walks -</h3>
                    <span className="bar"></span>
                    <p className="btc">Benefits</p>
                    <p>* Comprehensive Fitness Assessment *</p>
                    <p>* Aerobics *</p>
                    <p>* Health Tips *</p>
                    <span className="bar"></span>
                    <p>- 12 to 25 people - <span className="prices">GH 3,000</span> -</p>
                    <p>- 26 to 50 people - <span className="prices">GH 4,000</span> -</p>
                    <p>- 51 to 75 people - <span className="prices">GH 5,500</span> -</p>
                    <p>- 75 people and above - <span className="prices">GH 7,000</span> -</p>
                    <Link to="/contact" className="btn pc">Book Now</Link>
                </div>

                <div className="card">
                    <h3>- Health & Wellness Retreat -</h3>
                    <span className="bar"></span>
                    <p className="btc">Benefits</p>
                    <p>* 3 Days Training (Circuit & Compound Movement) *</p>
                    <p>* Comprehensive Fitness Assessment *</p>
                    <p>* Free 21 Day Meal Plan *</p>
                    <p>* BeaFit Healthy Salad & Smoothies *</p>
                    <p>* BeaFit Energy Drink *</p>
                    <span className="bar"></span>
                    <p>- 12 to 25 people - <span className="prices">GH 4,000</span> -</p>
                    <p>- 26 to 50 people - <span className="prices">GH 5,500</span> -</p>
                    <p>- 51 to 75 people - <span className="prices">GH 6,300</span> -</p>
                    <p>- 76 people and above - <span className="prices">GH 6,500</span> -</p>
                    <Link to="/contact" className="btn pc">Book Now</Link>
                </div>

            </div>
        </div>
    )
}

export default PricingCard