import React from "react";
import BackToTopButton from '../components/BackToTopButton'
import Consultation from "../components/Consultation";
import Copyright from "../components/Copyright";
import Footer from "../components/Footer";
import HeroImg2 from "../components/HeroImg2";
import Navbar from "../components/Navbar";
import PricingCard from "../components/PricingCard";
// import Work from "../components/Work";

const Services = () => {
    return (
        <div>
            <Navbar />
            <HeroImg2 heading="SERVICES" text="What we offer" />
            {/* <Work /> */}
            <PricingCard />
            <Consultation />
            <Footer />
            <Copyright />
            <BackToTopButton />
        </div>
    );
};

export default Services;