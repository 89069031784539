import React from "react";
import BackToTopButton from "../components/BackToTopButton";
import Consultation from "../components/Consultation";
import Copyright from "../components/Copyright";
import Footer from "../components/Footer";
import HeroImg from "../components/HeroImg";
import InfoCards from "../components/InfoCards";
import Navbar from "../components/Navbar";
// import Work from "../components/Work"

const Home = () => {
  return (
    <div>
      <Navbar />
      <HeroImg />
      <InfoCards />
      {/* <Work /> */}
      <Consultation />
      <Footer />
      <Copyright />
      <BackToTopButton />
    </div>
  );
};

export default Home;
