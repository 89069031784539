import React from 'react'
import BackToTopButton from '../components/BackToTopButton';
import Copyright from '../components/Copyright';
import Footer from "../components/Footer";
import Form from '../components/Form';
import Heroimg3 from '../components/HeroImg3';
import Navbar from "../components/Navbar";


const Contact = () => {
    return (
        <div>
            <Navbar />
            <Heroimg3 heading="CONTACT" text="Need help with getting fit and in shape? " text2="let's have a chat" />
            <Form />
            <Footer />
            <Copyright />
            <BackToTopButton />
        </div>
    )
}

export default Contact