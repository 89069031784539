import React from 'react'
import "./CoprightStyles.css"


const Copyright = () => {
    return (
        <div className='copyright'>
            <span className='copyright-bar'></span>
            <h5 className='copyright-text'>Copyright © 2023 Beafit Ghana All Rights Reserved.</h5>
        </div>
    )
}

export default Copyright