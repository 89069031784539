import React from "react";
import "./InfoCardsStyles.css";

const InfoCards = () => {
  return (
    <div className="info-card">
      <div className="info-card-container">
        <div className="actual-card">
          <h3>Beafit Ghana </h3>
          <p>
            We specialize in helping people achieve their fitness goals through
            personalized training programs, nutritional guidance, and
            motivational coaching.
          </p>
        </div>

        <div className="actual-card middle">
          <h3>Certified Fitness Consultant </h3>
          <p>
            A certified fitness expert, and currently Ghana’s 1st Fitness
            Ambassador for Tourism.
          </p>
        </div>

        <div className="actual-card">
          <h3>Our Philosophy </h3>
          <p>
            We firmly believe that to stay fit and healthy is a personal
            conversation to have with one's self in other not to wear your age
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoCards;
