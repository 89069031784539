import { React, useEffect, useState } from 'react'
import "./BackToTopButtonStyles.css";



function BackToTopButton() {
    const [backToTopButton, setBackToTopButton] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 600) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    })

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div>
            {backToTopButton && (
                <button className="scroller" onClick={scrollUp}>^</button>
            )}
        </div>
    )
}

export default BackToTopButton