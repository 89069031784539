import React from "react";
import "./AboutContentStyles.css";
import AboutPic1 from "../assets/bea-6.jpeg";
// import AboutPic2 from "../assets/beafit-3.jpg";

const AboutContent = () => {
    return (
        <div className="about">
            <div className="about-left">
                <h1>Who Is BeaFit?</h1>
                <p>
                    Hi! I'm Beatrice Afful but people know me by so many names but what
                    stands out and is more popular among them is Bea De Bully (Bea the
                    Bully). If you assumed it is a nickname for something bad then you are
                    so wrong. why such an intimidating Nickname you may wonder. Well I
                    acquired this name from my greusome yet entertaining and fun workouts.
                    The Idea behind the name is that "I bully the fat out of you" (I bully
                    you to fitness). Everywhere I go people ask me "what is the secret?".
                    well surprisingly nothing at all, just #EatWell #SleepWell and
                    #Workout. It is not as simple as it sounds to some people. But with me
                    as your coach I can assist you with everything you need to
                    #BeYourAgeBully.  <a href="/contact">    ASK ME HOW!!</a>{" "}
                </p>
            </div>

            <div className="about-right">
                <div className="img-container">
                    <img src={AboutPic1} className="actual-img" alt="actual"></img>
                </div>
                {/* <div className="img-container">
                    <div className="img-stack top">
                        <img src={AboutPic1} className="about-img" alt="about-img"></img>
                    </div>
                    <div className="img-stack bottom">
                        <img src={AboutPic2} className="about-img" alt="about-img2"></img>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default AboutContent;
